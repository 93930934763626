@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.up-2-cta-tile-link {
    &:hover {
        text-decoration: none;
    }
}

.up-2-cta-tile {
    a {
        /* stylelint-disable-next-line */
        &:hover {
            text-decoration: none;
        }
    }

    .up-2-cta-content-button {
        margin-top: 0;
    }
}

.up-2-cta-content-subtitle {
    color: var(--text-primary);
    font-family: var(--font-primary);
    font-size: var(--text-xs);

    @include media-breakpoint-up(lg) {
        font-size: var(--text-sm);
    }

    .up-2-cta-tile--alt-colors & {
        color: var(--text-secondary);
    }
}

.up-2-cta-content-title {
    color: var(--text-primary);
    font-family: var(--font-primary);
    font-size: var(--text-base);
    font-weight: 500;

    @include media-breakpoint-up(lg) {
        font-size: var(--text-lg);
    }

    .up-2-cta-tile--alt-colors & {
        color: var(--text-secondary);
    }
}

.up-2-cta-content-description {
    color: var(--text-primary);
    font-family: var(--font-primary);
    font-size: var(--text-xs);

    @include media-breakpoint-up(lg) {
        font-size: var(--text-sm);
    }

    .up-2-cta-tile--alt-colors & {
        color: var(--text-secondary);
    }

    &:hover {
        text-decoration: none;
    }
}

.up-2-cta-content-button {
    background-color: var(--background-primary);
    font-family: var(--font-primary);
    font-size: var(--text-sm);

    &:hover {
        background-color: var(--background-secondary);
    }

    span {
        line-height: 40px;

        @include media-breakpoint-up(lg) {
            line-height: 48px;
        }
    }
}

.up-2-cta-content-button-text {
    color: var(--text-primary);
    font-size: var(--text-sm);
    font-weight: 700;
}

.up-2-cta-content-button-text-secondary {
    color: var(--text-primary);
    font-size: var(--text-sm);
}
