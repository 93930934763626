@import "../../../../../../../../app_core_page_designer/cartridge/client/default/scss/experience/components/assets/up2CtaTile";
@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.up-2-cta-tile {
    .up-2-cta-content-button {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        display: flex;
    }

    .text-shadow {
        text-shadow: 1px 3px 5px rgba($color-black, 0.5);
    }
}

.experience-component {
    .up-2-cta-tile {
        /* style for start align */
        .content-text-align-start-for-small {
            @include media-breakpoint-down(sm) {
                justify-content: start;
            }
        }

        .content-text-align-start-for-medium {
            @include media-breakpoint-only(md) {
                justify-content: start;
            }
        }

        .content-text-align-start-for-large {
            @include media-breakpoint-up(lg) {
                justify-content: start;
            }
        }

        /* style for end align */
        .content-text-align-end-for-small {
            @include media-breakpoint-down(sm) {
                justify-content: end;
            }
        }

        .content-text-align-end-for-medium {
            @include media-breakpoint-only(md) {
                justify-content: end;
            }
        }

        .content-text-align-end-for-large {
            @include media-breakpoint-up(lg) {
                justify-content: end;
            }
        }

        /* style for center align */
        .content-text-align-middle-for-small {
            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }
        .content-text-align-middle-for-medium {
            @include media-breakpoint-only(md) {
                justify-content: center;
            }
        }
        .content-text-align-middle-for-large {
            @include media-breakpoint-up(lg) {
                justify-content: center;
            }
        }
    }
}