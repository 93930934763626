@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./up2CtaTile__typography";

.up-2-cta-tile-link {
    display: block;
    position: relative;
    z-index: 10;
}

.up-2-cta-tile {
    cursor: pointer;
    position: relative;

    &.margins-enabled {
        margin-top: 15px;

        @include media-breakpoint-up(md) {
            margin-top: 32px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 21px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 31px;
        }
    }
}

/* stylelint-disable-next-line */
.experience-assets-up2CtaTile {
    width: 100%;
    margin-top: 12px;

    @include media-breakpoint-up(md) {
        margin-top: 20px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 0;

        .up-2-cta & {
            width: calc(50% - 10px);
        }
    }
}

.up-2-cta-tile-inner-content-wrapper {
    position: relative;
}

.up-2-cta-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 32px;
}

.up-2-cta-content {
    display: flex;
    // flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
        justify-content: center;
        width: 56%;
    }
}

.up-2-cta-content-subtitle {
    margin-bottom: 16px;
}

.up-2-cta-content-title {
    margin-bottom: 16px;
}

.up-2-cta-content-description {
    max-height: 65px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        max-height: 80px;
    }

    @include media-breakpoint-up(lg) {
        max-height: 97px;
    }

    @include media-breakpoint-up(xl) {
        max-height: 100px;
    }
}

.up-2-cta-content-button {
    align-items: center;
    border: 0;
    border-radius: 4px;
    display: flex;
    height: 40px;
    justify-content: center;
    line-height: 20px;
    margin: 24px 0 0;
    overflow: hidden;
    padding: 10px 16px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    z-index: 20;

    @include media-breakpoint-up(lg) {
        padding: 14px 24px;
    }

    /* stylelint-disable-next-line */
    &:focus {
        text-decoration: none;
    }
}

.up-2-cta-tile-image {
    width: 100%;
}
